<template>
  <div>
    <div class="diary" v-for="(event, i) in diary" :key="i">
      <h3 class="diary__date" data-cy="diary__date">
        {{ i | getFrenchDate }}
      </h3>
      <h3 class="diary__author" data-cy="diary__author">
        {{ getUser(event.data) }}
      </h3>
      <h3 class="diary__action" data-cy="diary__action">{{ event.message }}</h3>
      <ButtonWithIcon
        :style="buttonOpacity"
        icon="icon-eye"
        :label="$t('button.view_email')"
        button-class="diary__button-view btn-secondary"
      />
    </div>
  </div>
</template>

<script>
import ButtonWithIcon from "@/components/button/ButtonWithIcon"

export default {
  name: "Diary",
  components: {
    ButtonWithIcon,
  },
  props: {
    diary: { type: Object, required: true },
  },
  computed: {
    buttonOpacity() {
      return this.diary.hasEmail ? { opacity: 1 } : { opacity: 0 }
    },
  },
  created() {
    console.log(this.diary.events)
  },
  methods: {
    getUser(userId) {
      return (
        userId.sale.created_by.first_name +
        " " +
        userId.sale.created_by.last_name
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.diary {
  background-color: $blue20;
}
</style>
