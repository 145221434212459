<template>
  <div class="sidebar">
    <ul class="sidebar__dashboard-link">
      <li>
        <a class="sidebar__link" href="#">
          <span class="icon-speed-fast"></span>
          {{ $t("sidebar.dashboard") }}
        </a>
      </li>
    </ul>
    <h3 class="sidebar__identification">{{ $t("sidebar.identification") }}</h3>
    <ul>
      <li>
        <a class="sidebar__link" href="#">
          <span class="icon-speed-fast"></span>
          {{ $t("sidebar.contracts") }}
        </a>
      </li>
      <li>
        <a class="sidebar__link" href="#">
          <span class="icon-speed-fast"></span>
          {{ $t("sidebar.partners") }}
        </a>
      </li>
      <li>
        <a class="sidebar__link" href="#">
          <span class="icon-speed-fast"></span>
          {{ $t("sidebar.beneficiaries") }}
        </a>
      </li>
      <li>
        <a class="sidebar__link" href="#">
          <span class="icon-speed-fast"></span>
          {{ $t("sidebar.garages") }} & {{ $t("sidebar.thirdParties") }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideBar",
}
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $blue100;
  color: white;
  min-width: 200px;
  ul {
    margin-left: 0.8rem;
  }
  a {
    color: white;
  }
}
.sidebar__dashboard-link {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.sidebar__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-bottom: 1rem;
  span {
    margin-right: 0.5rem;
  }
}
.sidebar__identification {
  margin-left: 0.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
</style>
