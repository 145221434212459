<template>
  <nav class="navbar">
    <div class="navbar__searchbar">
      <input
        data-cy="contract-search-input"
        type="text"
        v-model="searchText"
        id="search"
        name="searchbar"
        :placeholder="$t('topbar.searchPlaceholder')"
        @keyup.enter="onSearch"
        @focusin="onResetClick"
      />
      <button-with-icon
        data-cy="contract-search-button"
        icon="icon-magnifier"
        :label="$t('topbar.search')"
        @click="onSearch"
      ></button-with-icon>
      <div
        @click="onResetClick"
        class="navbar__searchresult"
        v-if="hasSearchError"
        data-cy="contract-search-error"
      >
        <div class="navbar_error-item">
          <div>{{ getSearchError.search_text }}</div>
          <div><em class="icon-cross" /></div>
        </div>
      </div>
      <div
        class="navbar__searchresult"
        v-if="isSearchLoading && !hasSearchError"
        data-cy="contract-search-loading"
      >
        <Spinner :no-message="true"></Spinner>
      </div>
      <div
        class="navbar__searchresult"
        v-if="!isSearchLoading && !hasSearchError && hasSearchResults === false"
        @click="onResetClick"
        data-cy="contract-search-no-result"
      >
        <div>{{ $t("topbar.noResultFound") }}</div>
      </div>
      <div
        class="navbar__searchresult"
        v-if="getContractsSearchResult"
        data-cy="contract-search-result-list"
      >
        <div
          v-for="contract in getContractsSearchResult"
          :key="contract.num"
          @click="onSearchResultItemClick(contract)"
          :data-cy="`contract-search-result-item-${contract.num}`"
        >
          <div>{{ contract.num }}</div>
          <div>
            {{ contract.sale.beneficiary.user.first_name }}
            {{ contract.sale.beneficiary.user.last_name }}
          </div>
          <div class="navbar__resultitem-small">
            {{ contract.sale.vehicle.make }}
            {{ contract.sale.vehicle.model }}
          </div>
          <div v-if="contract.sale.vehicle.hasOwnProperty('plate')">
            {{ contract.sale.vehicle.plate }}
          </div>
        </div>
      </div>
    </div>
    <div class="navbar__user">
      <div class="navbar__user-info">
        <div class="navbar__fullname-company">
          <h4 class="gilroy-text">{{ fullname }}</h4>
          <p>{{ companyName }}</p>
        </div>
        <img src="@/assets/img/profil-picture.png" :alt="fullname" />
      </div>
      <div
        class="navbar__phone"
        @click="toggleAirCallPhoneVisibility"
        data-cy="navbar-phone"
      >
        <span class="icon-phone-outgoing"></span>
      </div>
      <div class="navbar__mail">
        <span class="icon-envelope"></span>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex"
import ButtonWithIcon from "../../components/button/ButtonWithIcon"

export default {
  name: "NavBar",
  components: { ButtonWithIcon },
  data() {
    return {
      isSearchLoading: false,
      hasSearchResults: null,
      searchText: "",
      currentUser: {
        first_name: "Hélène",
        last_name: "Dortense",
      },
      companyName: "Caarea",
    }
  },
  computed: {
    ...mapGetters("aircall", ["isVisible"]),
    ...mapGetters("contract", [
      "getContractsSearchResult",
      "hasSearchError",
      "getSearchError",
    ]),
    fullname() {
      return this.currentUser.first_name + " " + this.currentUser.last_name
    },
  },
  methods: {
    async onSearch() {
      this.isSearchLoading = true
      try {
        this.hasSearchResults = await this.$store.dispatch(
          "contract/search",
          this.searchText
        )
      } finally {
        this.isSearchLoading = false
      }
    },
    onSearchResultItemClick(contract) {
      const path = `/${this.$route.params.lang}/contracts/${contract.id}`
      if (this.$route.path !== path) {
        this.$router.push({
          path: path,
          params: { contractId: contract.id },
        })
      }
      this.$store.dispatch("contract/resetSearch")
      this.hasSearchResults = null
    },
    onResetClick() {
      this.$store.dispatch("contract/resetSearch")
      this.hasSearchResults = null
    },
    toggleAirCallPhoneVisibility() {
      this.$store.dispatch("aircall/changeVisibility", !this.isVisible)
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 2/3;
  margin-right: 3rem;
}
.navbar__searchbar {
  display: grid;
  position: relative;
  grid-template-columns: 4fr 1fr;
  margin-left: 3rem;
  margin-right: 3rem;
  input {
    padding: 0.6rem 1rem 0.6rem 1rem;
    color: $primary;
    border: solid 1px $blue20;
    transition: all 0.2s ease;
    position: relative;
    &::placeholder {
      color: $blue20;
    }
    &:hover {
      border-color: $blue40;
    }
    &:focus-visible {
      outline: none;
      border-color: $blue40;
    }
  }
}
.navbar__searchresult {
  position: absolute;
  width: 80%;
  background-color: white;
  top: 100%;
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  div {
    // search result item flex container
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.13s ease;
    &:hover {
      background-color: $purple20;
    }
    &.navbar_error-item {
      text-align: right;
      justify-content: space-between;
    }
    div {
      // flex items
      word-break: break-word;
    }
  }
}
.navbar__user {
  display: flex;
  align-items: center;
}
.navbar__user-info {
  display: flex;
  margin-right: 3rem;
  img {
    width: 3rem;
  }
}
.navbar__fullname-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.navbar__phone {
  cursor: pointer;
  position: relative;
  margin-right: 3rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    border: 1px solid lightgrey;
    border-radius: 50%;
    box-shadow: 2px -1px 17px -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 2px -1px 17px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px -1px 17px -1px rgba(0, 0, 0, 0.15);
    transform: translate(-28%, -28%);
  }
}
.navbar__mail {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    border: 1px solid lightgrey;
    border-radius: 50%;
    box-shadow: 2px -1px 17px -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 2px -1px 17px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px -1px 17px -1px rgba(0, 0, 0, 0.15);
    transform: translate(-30%, -25%);
  }
}
</style>
