<template>
  <div class="layout">
    <TopBar></TopBar>
    <div class="layout__container">
      <SideBar></SideBar>
      <div class="layout__main-content">
        <div class="layout__dashboard">
          <span class="icon-home"></span>
          <ul>
            <li>
              <a href="#">{{ $t("sidebar.dashboard") }}</a>
            </li>
          </ul>
        </div>
        <AirCallPhone></AirCallPhone>
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "./components/TopBar"
import SideBar from "./components/SideBar"
import AirCallPhone from "@/components/AirCallPhone"

export default {
  name: "Layout",
  components: { AirCallPhone, TopBar, SideBar },
}
</script>

<style lang="scss" scoped>
.layout__container {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
}
.layout__main-content {
  grid-column: 2/3;
}
.layout__dashboard {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  -webkit-box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.85);
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.85);
  height: 2.5rem;
  span {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
</style>
