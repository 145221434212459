import UrlService from "../services/technical/UrlService"
import HttpService from "@/services/technical/HttpService"

// STATES (snake_case)
const state = {
  diaries: null,
  diary: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_DIARIES(state, payload) {
    state.diaries = payload
  },
}

// ACTIONS (camelCase)
const actions = {
  async setDiaries({ commit }, contractId) {
    try {
      const diaries = await HttpService.get(
        UrlService.render("diaries", { id: contractId })
      )
      console.log("diaries", diaries)
      commit("SET_DIARIES", diaries)
    } catch (e) {
      console.error("failed: ", e.response.status)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getDiaries: (state) => state.diaries,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
