<template>
  <div>
    <h3 class="diaries__title">{{ $t("contract.diaries.title") }}</h3>
    <div class="diary">
      <h5 class="diary__date contract__data-name">
        {{ $t("contract.diaries.date") }}
      </h5>
      <h5 class="diary__author contract__data-name">
        {{ $t("contract.diaries.author") }}
      </h5>
      <h5 class="diary__action contract__data-name">
        {{ $t("contract.diaries.action") }}
      </h5>
      <ButtonWithIcon
        icon="icon-eye"
        :label="$t('button.view_all')"
        button-class="diary__button-view btn-secondary"
      />
    </div>
    <Diary
      v-for="(diary, index) in diaries"
      :key="index"
      :diary="diary.events[0]"
    ></Diary>
  </div>
</template>

<script>
import ButtonWithIcon from "@/components/button/ButtonWithIcon"
import Diary from "./Diary"

export default {
  name: "DiariesList",
  components: {
    ButtonWithIcon,
    Diary,
  },
  props: {
    diaries: {
      type: Array,
    },
  },
}
</script>

<style lang="scss" scoped>
.diaries__title {
  padding-bottom: 0.5rem;
}
</style>
