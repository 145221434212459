<template>
  <div
    class="aircallphone-container"
    :class="visibilityClass"
    data-cy="aircall-phone"
  >
    <div class="arrow-up"></div>
    <div id="phone"></div>
  </div>
</template>

<script>
import AircallPhone from "aircall-everywhere"
import { mapGetters } from "vuex"

export default {
  name: "AirCallPhone",
  computed: {
    ...mapGetters("aircall", ["isVisible"]),
    visibilityClass() {
      return this.isVisible ? "" : "d-none"
    },
  },
  methods: {
    onIncomingCall(callInfo) {
      console.log(`Call received from ${callInfo.from}`)

      // If phone is not visible, make it visible
      if (!this.isVisible) {
        this.$store.dispatch("aircall/changeVisibility", true)
      }
    },
  },
  mounted() {
    // Note : saving the new AircallPhone instance into a data (rather than a local variable)
    // makes the feature not to work (for an unknown reason for now) !
    const phone = new AircallPhone({
      domToLoadPhone: "#phone",
    })

    // Specify callback to call on "incoming call" event
    phone.on("incoming_call", this.onIncomingCall)
  },
}
</script>

<style lang="scss" scoped>
.aircallphone-container {
  position: fixed;
  top: 106px;
  right: 0;
  z-index: 30;
}
.d-none {
  display: none;
}
</style>
