<template>
  <button
    @click="$emit('click')"
    type="button"
    :class="['btn-container', 'button', ...buttonClass]"
  >
    <em :class="icon"></em>
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "ButtonWithIcon",
  props: {
    icon: { type: String, required: true },
    label: { type: String, required: true },
    buttonClass: { type: String, default: "btn-primary" },
  },
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-width: 75%;

  i {
    font-size: 1.5rem;
  }
}
</style>
