// STATES (snake_case)
const state = () => ({
  is_visible: false,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_VISIBILITY(state, isVisible) {
    state.is_visible = isVisible
  },
}

// ACTIONS (camelCase)
const actions = {
  changeVisibility({ commit }, isVisible) {
    commit("SET_VISIBILITY", isVisible)
  },
}

// GETTERS (camelCase)
const getters = {
  isVisible: (state) => state.is_visible,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
