<template>
  <header class="header">
    <div class="header__logo">
      <a href="/">
        <img src="@/assets/img/logo-header.png" alt="Caarea Solutions" />
      </a>
      <span class="gilroy-text">{{ appName }}</span>
    </div>
    <NavBar></NavBar>
  </header>
</template>

<script>
import NavBar from "./NavBar"

export default {
  name: "TopBar",
  components: { NavBar },
  data() {
    return {
      appName: "Claims",
    }
  },
}
</script>
<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray;
  height: 10vh;
}
.header__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  border-right: 1px solid $light-gray;
}
</style>
